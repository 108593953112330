.mapboxgl-ctrl-bottom-right {
  bottom: 65px;
}
.mapboxgl-ctrl-bottom-left {
  bottom: 65px;
}
.mapboxgl-ctrl-group {
  background-color: #faf6e0;
  border-radius: 0;
}
.mapboxgl-ctrl-group > button {
  width: 40px;
  height: 40px;
  outline: none;
}
.mapboxgl-ctrl-group > button:focus {
  box-shadow: unset;
}
.mapboxgl-ctrl > button:hover {
  background-color: unset;
}
.mapboxgl-ctrl-group > button:focus:first-child {
  border-radius: 0;
}
.mapboxgl-ctrl-group > button:focus:last-child {
  border-radius: 0;
}
/*Inden lokation er hentet*/
.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate {
  background-image: url('../../assets/svg/location.svg');
  background-size: 22px 21px;
  background-repeat: no-repeat;
  background-position: center;
}
/*Mens lokation hentes*/
.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-waiting.mapboxgl-ctrl-geolocate-active {
  background-image: url('../../assets/svg/location-waiting.svg');
  background-size: 22px 21px;
  background-repeat: no-repeat;
  background-position: center;
}
.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active::before {
  background-image: none;
}
.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate::before {
  background-image: none;
}
.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-background::before {
  background-image: none;
}
/*Når lokation er hentet*/
.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active {
  background-image: url('../../assets/svg/location-active.svg');
  background-size: 22px 21px;
  background-repeat: no-repeat;
  background-position: center;
}

.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in {
  background-image: url('../../assets/svg/zoom-in.svg');
  background-size: 17px 17px;
  background-repeat: no-repeat;
  background-position: center;
}
.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
  background-image: url('../../assets/svg/zoom-out.svg');
  background-size: 17px 2px;
  background-repeat: no-repeat;
  background-position: center;
}

.mapboxgl-user-location-dot {
  background-color: #4d9090;
  z-index: 1;
}
.mapboxgl-user-location-dot::before {
  background-color: #4d9090;
}
