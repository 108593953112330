.verticalSpaceSmall {
  margin-bottom: 1cm;
}

.verticalSpaceBig {
  margin-bottom: 3cm;
}

.drawer {
  width: auto;
  height: 100px;
  z-index: 2; /* This one got a parent element with a value of 1200*/
}

.popupBox {
  position: absolute;
  z-index: 1202;
  top: 100px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background-color: white;
  border: solid 2px black;
  border-radius: 8px;
}

.loading {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:1000;
  background-color:rgba(22,22,44,0.2);
}
