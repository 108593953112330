html {
  box-sizing: border-box;
  background-color: #f3edcd;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #45241c;
}

h2,
h3,
h4 {
  font-weight: 500;
}
button {
  color: #45241c;
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.player-marker {
  background-image: url('assets/svg/user.svg');
  background-size: cover;
  width: 45px;
  height: 45px;
  filter: drop-shadow(1px 1px 5px rgba(69, 36, 28, 0.3));
}
